import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "catSlug",
    "countrySlugs", 
    "subcatSlugs", 
    "companySlugs", 
    "priceLevels", 
    "productsFrame", 
    "nextPage",
    "query"
  ];

  handleResults(e) {
    var item_type = e.target.dataset['item'];
    this._cleanParams();
    history.replaceState(null, null, `?${this.params}`);
    this._updateBadge(item_type);
    // this._badgeRowDisplay();
    this._handleSearch();
  }

  handleQuery() {
    this.queryTarget.addEventListener('keyup', (e) => {
      e.preventDefault();
      if (e.code === 'Enter' && e.keyCode === 13) this._handleResults('query');
    })
  }

  handleInput() {
    this._handleResults('query');
  }

  handleCategory(e) {
    var categoryId = e.target.dataset['id'];
    this._unCheckedSubCategory(categoryId);
    this._handleResults('category');
  }
  
  handleSubCategory(e) {
    var categoryId = e.target.dataset['parentid'];
    var currentCategoryCheckbox = document.querySelector(`#cat_slugs_${ categoryId }`);
    var subcategoriesCheckbox = [...document.querySelectorAll(`.category_parent_${ categoryId }`)];
    subcategoriesCheckbox = subcategoriesCheckbox.filter((checkbox) => checkbox.checked == true);
    
    if (subcategoriesCheckbox.length == 0) currentCategoryCheckbox.checked = false;
    if (subcategoriesCheckbox.length == 1) currentCategoryCheckbox.checked = true;
    
    this._handleResults('sub_category');
  }
  
  handleRemoveQuery(e) {
    var item_type = e.target.dataset['item'];
    var item_slug = e.target.dataset['slug'];

    // if (item_type == "category") {
    //   this._unChecked(this.catSlugsTarget, item_slug);
    //   this._unCheckedSubCategory(item_slug);
    // }
    if (item_type == "sub_category") this._unChecked(this.subcatSlugsTargets, item_slug);
    if (item_type == "country") this._unChecked(this.countrySlugsTargets, item_slug);
    if (item_type == "company") this._unChecked(this.companySlugsTargets, item_slug);  
    if (item_type == "price_level") this._unChecked(this.priceLevelsTargets, item_slug);  

    this._handleResults(item_type);
  }

  handleCleanQuery(e) {
    this._unCheckedAll();
    this._handleResults('all');
  }

  nextItems(e) {
    this._cleanParams();
    var nextPage = e.target.dataset['nextpage'];
    e.target.classList.add('is-hidden');
    history.replaceState(null, null, `?${this.params}&page=${nextPage}`);
    this.productsFrameTarget.src = `/search_results?${this.params}&page=${nextPage}`;
  }

  _handleResults(item_type) {
    this._cleanParams();
    history.replaceState(null, null, `?${this.params}`);
    this._updateBadge(item_type);
    // this._badgeRowDisplay();
    this._handleSearch();
  }

  _handleSearch() {
    this.productsFrameTarget.src = `/search_results?${this.params}`
  }

  _cleanParams() {
    const cleanURL = location.protocol + "//" + location.host + location.pathname;
    history.replaceState({}, document.title, cleanURL);
  }

  _toQueries(checkboxes, name) {
    var query = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => `${name}%5B%5D=${checkbox.value}`);
    if (query.length >= 1) return query.join("&");
    return `${name}=`;
  }

  _unChecked(checkboxes, item_slug) {
    checkboxes.forEach((checkbox) => {
      if (checkbox.value == item_slug) checkbox.checked = false;
    })
  }

  _unCheckedAll() {
    var checkboxes = document.querySelectorAll('.checkboxes');
    checkboxes.forEach((checkbox) => checkbox.checked = false);
  }

  _unCheckedSubCategory(categoryId) {
    var currentCheck = document.querySelector(`#cat_slugs_${ categoryId }`);
    var subcategoriesCheckbox = document.querySelectorAll(`.category_parent_${ categoryId }`);

    subcategoriesCheckbox.forEach(checkbox => {
      if (currentCheck.checked) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    });
  }

  _checked(checkboxes) {
    return checkboxes.filter((checkbox) => checkbox.checked == true);
  }

  _updateBadge(item_type) {
    // if (item_type == "category") this._updateCategoryBadge();
    if (item_type == "sub_category") this._updateCategoryBadge();
    if (item_type == "country") this._updateCountryBadge();
    if (item_type == "company") this._updateCompanyBadge();
    if (item_type == "price_level") this._updatepriceLevelBadge();
    if (item_type == "all") {
      this._updateCategoryBadge();
      this._updateCountryBadge();
      this._updateCompanyBadge();
      this._updatepriceLevelBadge();
    }
  }

  // _updateCategoryBadge() {
  //   var categoryCheckboxes = this._checked(this.catSlugsTargets);
  //   var subCategoryCheckboxes = this._checked(this.subcatSlugsTargets);
  //   var catSlugsCount = document.querySelector('#cat_slugs_count');
  //   var total = categoryCheckboxes.length + subCategoryCheckboxes.length;
  //   if (total > 0) {
  //     catSlugsCount.innerHTML = `<span class="tag is-light is-success is-small is-rounded w-600 ml-2">${total.toString()}</span>`;
  //   } else {
  //     catSlugsCount.innerHTML = '';
  //   }
  // }

  _updateCategoryBadge() {
    var subCategoryCheckboxes = this._checked(this.subcatSlugsTargets);
    var subCatSlugsCount = document.querySelector('#sub_cat_slugs_count');
    var length = subCategoryCheckboxes.length.toString();
    console.log(subCategoryCheckboxes, 'subCategoryCheckboxes')
    if (length > 0) {
      subCatSlugsCount.innerHTML = `<span class="tag is-light is-success is-small is-rounded w-600 ml-2">${length.toString()}</span>`;
    } else {
      subCatSlugsCount.innerHTML = '';
    }
  }
  
  _updateCountryBadge() {
    var checkboxes = this._checked(this.countrySlugsTargets);
    var countrySlugsCount = document.querySelector(`#country_slugs_count`);
    var length = checkboxes.length.toString();
    if (length > 0) {
      countrySlugsCount.innerHTML = `<span class="tag is-light is-info is-small is-rounded w-600 ml-2">${length.toString()}</span>`;
    } else {
      countrySlugsCount.innerHTML = '';
    }
  }

  _updateCompanyBadge() {
    var checkboxes = this._checked(this.companySlugsTargets);
    var companySlugsCount = document.querySelector(`#company_slugs_count`);
    var length = checkboxes.length;
    if (length > 0) {
      companySlugsCount.innerHTML = `<span class="tag is-light is-danger is-small is-rounded w-600 ml-2">${length.toString()}</span>`;
    } else {
      companySlugsCount.innerHTML = '';
    }
  }

  _updatepriceLevelBadge() {
    var checkboxes = this._checked(this.priceLevelsTargets);
    var priceLevelCount = document.querySelector(`#price_levels_count`);
    var length = checkboxes.length;
    if (length > 0) {
      priceLevelCount.innerHTML = `<span class="tag is-light is-warning is-small is-rounded w-600 ml-2">${length.toString()}</span>`;
    } else {
      priceLevelCount.innerHTML = '';
    }
  }

  _badgeRowDisplay() {
    // var categoryCheckboxes = this._checked(this.catSlugsTarget);
    var subCategoryCheckboxes = this._checked(this.subcatSlugsTargets);
    var countryCheckboxes = this._checked(this.countrySlugsTargets);
    var companyCheckboxes = this._checked(this.companySlugsTargets);
    var priceLevelsCheckboxes = this._checked(this.priceLevelsTargets);
    var total = subCategoryCheckboxes.length + countryCheckboxes.length + companyCheckboxes.length + priceLevelsCheckboxes.length;
    // var total = categoryCheckboxes.length + subCategoryCheckboxes.length + countryCheckboxes.length + companyCheckboxes.length + priceLevelsCheckboxes.length;
    
    if (total == 0) {
      document.querySelector('#badgeRow').classList.add('is-hidden');
    } else {
      document.querySelector('#badgeRow').classList.remove('is-hidden');
    }
  }

  get params() {
    return [this.query, this.countrySlugs, this.catSlug, this.subcatSlugs, this.companySlugs, this.priceLevels].join("&");
  }

  get query() {
    return `query=${this.queryTarget.value || ''}`;
  }

  get catSlug() {
    return `cat_slug=${this.catSlugTarget.value}`;
  }

  get countrySlugs() {
    return this._toQueries(this.countrySlugsTargets, 'country_slugs');
  }

  get subcatSlugs() {
    return this._toQueries(this.subcatSlugsTargets, 'sub_cat_slugs');
  }

  get companySlugs() {
    return this._toQueries(this.companySlugsTargets, 'company_slugs');
  }

  get priceLevels() {
    return this._toQueries(this.priceLevelsTargets, 'price_levels');
  }
}
