import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [ "content", "icon" ]
  }

  toggle() {
    this.contentTarget.classList.toggle('is-active');
  }

  close() {
    this.contentTarget.classList.remove('is-active');
  }
}