import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["files"];

  addFile(e) {
    const originalInput = e.target
    const originalParent = originalInput.parentNode

    const selectedFile = document.createElement("div");
    selectedFile.className = "file-selected";
    selectedFile.append(originalInput);
  
    // Create label (the visible part of the new input element) with the name of
    // the selected file.
    var labelNode = document.createElement("label");
    var textElement = document.createTextNode(originalInput.files[0].name);
    labelNode.appendChild(textElement);
    selectedFile.appendChild(labelNode);
    console.log(originalInput.files[0]);
    // Add the selected file to the list of selected files
    this.filesTarget.append(selectedFile);
  
    // Create a new input field to use going forward
    const newInput = originalInput.cloneNode();
  
    // Clear the filelist - some browsers maintain the filelist when cloning,
    // others don't
    newInput.value = "";
  
    // Add it to the DOM where the original input was
    originalParent.append(newInput);
  }
}
