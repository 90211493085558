import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [ "content", "icon", "accordion" ]
  }

  initialize() {
    this.toggle()
  }

  change(event) {
    this.index = this.contentTargets.indexOf(event.currentTarget)
  }

  toggle() {
    if (this.contentTarget.dataset.expanded === "1") {
      this.collapse();
    } else {
      this.expand();
    }
  }

  collapse() {
    this.contentTarget.classList.remove("is-hidden")
    this.accordionTarget.classList.add("is-active")
    this.contentTarget.dataset.expanded = "0"
    this.iconTarget.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
    </svg>
    `
  }

  expand() {
    this.contentTarget.classList.add("is-hidden")
    this.accordionTarget.classList.remove("is-active")
    this.contentTarget.dataset.expanded = "1"
    this.iconTarget.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
    </svg>
    `
  }

  get index() {
    return parseInt(this.data.get("index") || 0)
  }

  set index(value) {
    this.data.set("index", value)
    this.toggle()
  }
}