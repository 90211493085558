import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [ "content", "icon", "accordion" ]
  }

  initialize() {
    this.toggle()
  }

  change(event) {
    this.index = this.contentTargets.indexOf(event.currentTarget)
  }

  toggle() {
    if (this.contentTarget.dataset.expanded === "1") {
      this.collapse();
    } else {
      this.expand();
    }
  }

  collapse() {
    this.contentTarget.classList.remove("is-hidden")
    this.accordionTarget.classList.add("is-active")
    this.contentTarget.dataset.expanded = "0"
    if (this.hasIconTarget) {
      this.iconTarget.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
        </svg>
      `
    }
  }

  expand() {
    this.contentTarget.classList.add("is-hidden")
    this.accordionTarget.classList.remove("is-active")
    this.contentTarget.dataset.expanded = "1"
    if (this.hasIconTarget) {
      this.iconTarget.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
      `
    }
  }

  toggleForAdmin(e) {
    e.preventDefault();
    this.contentTarget.classList.toggle('is-active');
  }

  get index() {
    return parseInt(this.data.get("index") || 0)
  }

  set index(value) {
    this.data.set("index", value)
    this.toggle()
  }
}