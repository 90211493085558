import { Controller } from "stimulus"
import lgThumbnail from 'lightgallery/plugins/thumbnail'

export default class extends Controller {
  static targets = [ 'gallery' ];

  connect() {
    this.body = document.querySelector('body');
    this.applyLightGalleryJs(null);
  }

  applyLightGalleryJs(e) {
    new LightGallery(this.galleryTarget, {
      plugins: [lgThumbnail],
      speed: 500
    });
  }
}
