import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [ "content" ]
  }

  toggle(e) {
    e.preventDefault();
    this.contentTarget.classList.toggle('is-active');
  }
}