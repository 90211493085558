import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button" ];

  initialize() {
    this.scrollHandleEvent = this.handleScroll.bind(this);
  }
  
  connect() {
    document.addEventListener("scroll", this.scrollHandleEvent);
  }
  
  disconnect() {
    document.removeEventListener("scroll", this.scrollHandleEvent);
  }
  
  handleScroll() {
    if (this.hasButtonTarget) {
      const top = window.pageYOffset || document.documentElement.scrollTop;
      
      if (top >= 800) {
        this.buttonTarget.classList.remove('is-hidden');
      } else {
        this.buttonTarget.classList.add('is-hidden');
      }
    }
  }

  scrollTop(e) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}
