import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["category"]
  onSelectChange(e) {
    const selectElt = e.target;
    const name = e.target.dataset['selection'];
    const linkedChild = this._linkedChild(selectElt, name);

    if (linkedChild === null) {return;}

    const value = e.target.value;
    const url = linkedChild.dataset['url'];

    this.loadChild(value, url, linkedChild);
  }

  loadChild(parentId, url, linkedChild) {
    Rails.ajax({
      url: `${url}?parent_id=${parentId}`,
      type: "get",
      success: ( (data) =>{
        this.onLoad(data.sub_categories, linkedChild); 
        this.categoryTarget.innerHTML = data.category.name
      })
    });
  }

  onLoad(data, linkedChild) {
    console.log(data.sub_categories)
    const pasedData = data.map(e => { return {'value': e.id, 'label': e.name, 'selected': (e.id == this.selected ? true : false)} });
    linkedChild.choices.clearStore();
    linkedChild.choices.setChoices(pasedData);
  }

  _get_element_container(elt) {
    const nested_field_container = elt.closest('.single-record');
    return nested_field_container === null ? elt.closest('form') : nested_field_container;
  }

  _linkedChild(elt, name) {
    return this._get_element_container(elt).querySelector(`select[data-parent='${name}']`)
  }
}
