import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["frame", "query", "catSlug", "countrySlugs", "companySlugs"];

  handleTyping(e) {
    var url = e.target.dataset['url'];
    var item = e.target.dataset['item'];
    var typingTime;
    var doneTypingInterval = 500;
    var checkboxes;
    
    if (item == 'category') checkboxes = this.catSlugTargets;
    if (item == 'country') checkboxes = document.querySelectorAll('sidebar-search-country-slug');
    if (item == 'company') checkboxes = this.companySlugsTargets;
    
    console.log(checkboxes)
    console.log(document.querySelectorAll('sidebar-search-country-slug'))
    var itemQuery = this._toQueries(checkboxes, 'checked');

    clearTimeout(typingTime);
    typingTime = setTimeout(() => this.handleResults(url, itemQuery), doneTypingInterval);
  }

  handleResults(url, itemQuery) {
    this.frameTarget.src = `${url}/?query=${this.queryTarget.value}&${itemQuery}`;
  }

  _toQueries(checkboxes, name) {
    var query = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => `${name}%5B%5D=${checkbox.value}`);
    if (query.length >= 1) return query.join("&");
    return `${name}=`;
  }
}
