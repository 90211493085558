import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ];

  copy(e){
    e.preventDefault();
    let tooltip = document.getElementById("myTooltip");
    this.copyToClipboard(this.inputTarget.value)
      .then(() => tooltip.innerHTML = "Copié !")
      .catch(() => console.log('error'));
  }

  copyToClipboard(textToCopy){
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.select();
      return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand('copy') ? res() : rej();
          textArea.remove();
      });
    }
  }
}