// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@hotwired/turbo-rails"
import lightGallery from 'lightgallery';
import './style.scss';
const Notifications = require("simple-notifications-solution");

window.LightGallery = lightGallery;
window.Choices = require("choices.js");
const flatpickr = require("flatpickr");
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import "controllers"

Rails.start()
ActiveStorage.start()

const notifications = new Notifications();
notifications.init();
require("trix")
require("@rails/actiontext")
