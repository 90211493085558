import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "submitButton" ]

  connect() {
    this.updateButtonState()
  }
  updateButtonState() {
    console.log(this.inputTarget.value)
    if (this.inputTarget.value === "") {
      this.submitButtonTarget.disabled = true
    } else {
      this.submitButtonTarget.disabled = false
    }
    
  }
  inputChanged() {
    this.updateButtonState()
  }
 
}


